import './main.css';
import { Elm } from './Main.elm';
import * as serviceWorker from './serviceWorker';

import '@polymer/paper-tooltip/paper-tooltip.js';

const AUTH_USER_LS_KEY = "PT_AUTH_USER_LS_KEY";

const flags = JSON.parse(window.localStorage.getItem(AUTH_USER_LS_KEY));

const app = Elm.Main.init({
    node: document.getElementById('root'),
    flags: flags
});

window.requestAnimationFrame(() => {
    setupLandingPage();
});

app.ports.saveAuthUserToLocalStorage.subscribe(authUser => {
    window.localStorage.setItem(AUTH_USER_LS_KEY, JSON.stringify(authUser));
});

app.ports.setupLandingPage.subscribe(() => {
    window.requestAnimationFrame(() => {
        setupLandingPage();
    });
});

app.ports.focusSmooth.subscribe(function(domId) {

    window.requestAnimationFrame(() => {
        const el = document.getElementById(domId);
        if (el != null) {
            el.scrollIntoView({behavior: "smooth", block: "center"});
            el.focus({preventScroll: true});

            if (el.type === "text" || el.type === "number" || el.type == "textarea") {
                el.select();
            }
        }
    });
});


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

function setupLandingPage() {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();

            document.querySelector(this.getAttribute('href')).scrollIntoView({
                behavior: 'smooth'
            });
        });
    });
    var video = document.getElementById("video_demo_manual_autoplay_in_js");
    if (video) {
        video.oncanplaythrough = function() {
            video.muted = true;
            video.play();
        }
    }
    var video2 = document.getElementById("video_demo_manual_autoplay_in_js_2");
    if (video2) {
        video2.oncanplaythrough = function() {
            video2.muted = true;
            video2.play();
        }
    }
    var video3 = document.getElementById("video_demo_manual_autoplay_in_js_3");
    if (video3) {
        video3.oncanplaythrough = function() {
            video3.muted = true;
            video3.play();
        }
    }

    var scrollpos = window.scrollY;
    var header = document.getElementById("header");
    var navcontent = document.getElementById("nav-content");
    var navaction = document.getElementById("navAction");
    var brandname = document.getElementById("brandname");
    var toToggle = document.querySelectorAll(".toggleColour");

    document.addEventListener("scroll", function () {
        /*Apply classes for slide in bar*/
        scrollpos = window.scrollY;

        if (header) {
            if (scrollpos > 10) {
                header.classList.add("bg-white");
                navaction.classList.remove("bg-white");
                navaction.classList.add("gradient");
                navaction.classList.remove("text-gray-800");
                navaction.classList.add("text-white");
                //Use to switch toggleColour colours
                for (var i = 0; i < toToggle.length; i++) {
                toToggle[i].classList.add("text-gray-800");
                toToggle[i].classList.remove("text-white");
                }
                header.classList.add("shadow");
                navcontent.classList.remove("bg-gray-100");
                navcontent.classList.add("bg-white");
            } else {
                header.classList.remove("bg-white");
                navaction.classList.remove("gradient");
                navaction.classList.add("bg-white");
                navaction.classList.remove("text-white");
                navaction.classList.add("text-gray-800");
                //Use to switch toggleColour colours
                for (var i = 0; i < toToggle.length; i++) {
                toToggle[i].classList.add("text-white");
                toToggle[i].classList.remove("text-gray-800");
                }

                header.classList.remove("shadow");
                navcontent.classList.remove("bg-white");
                navcontent.classList.add("bg-gray-100");
            }
        }
    });
}

// Toggle dropdown list
// https://gist.github.com/slavapas/593e8e50cf4cc16ac972afcbad4f70c8

// var navMenuDiv = document.getElementById("nav-content");
// var navMenu = document.getElementById("nav-toggle");

// document.onclick = check;
// function check(e) {
// var target = (e && e.target) || (event && event.srcElement);

// //Nav Menu
// if (!checkParent(target, navMenuDiv)) {
//     // click NOT on the menu
//     if (checkParent(target, navMenu)) {
//     // click on the link
//     if (navMenuDiv.classList.contains("hidden")) {
//         navMenuDiv.classList.remove("hidden");
//     } else {
//         navMenuDiv.classList.add("hidden");
//     }
//     } else {
//     // click both outside link and outside menu, hide menu
//     navMenuDiv.classList.add("hidden");
//     }
// }
// }
// function checkParent(t, elm) {
// while (t.parentNode) {
//     if (t == elm) {
//     return true;
//     }
//     t = t.parentNode;
// }
// return false;
// }
function cheatDeepEqual(obj1, obj2)
{
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}
